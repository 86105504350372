
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import CarsRateAlertService, { CarsRateAlertServiceS, notificationMessages } from '@/modules/cars/car.rate-alert.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import { IConfigDataTable } from '@/modules/cars/modules/settings/interfaces/alert-interfaces';
import CustomMenu from '@/modules/cars/modules/settings/components/custom-menu.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';

@Component({
    components: {
        CustomSwitch,
        CustomMenu,
        CustomLoader,
    },
})

export default class CarRateAlertSettings extends Vue {
    @inject(CarsRateAlertServiceS) private carsRateAlertService!: CarsRateAlertService;
    @inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    static title = 'Alerts & Notifications';

    get tableData() {
        const { data } = this.carsRateAlertService;

        if (!data) {
            return [];
        }
        const configData: IConfigDataTable[] = [];
        data.forEach(config => {
            configData.push({
                id: config.id,
                percentage: `${config.ratio}%`,
                location: config.locationIds.map(locationId => {
                    const location = this.carsSharedService.getLocationNameById(locationId) || { locationName: ' ' };
                    return location.locationName;
                }).join(', '),
                lok: config.lors.join(', '),
                pos: config.pos.join(', '),
                carCategory: config.carCategories.join(', '),
                type: config.fuelType.join(', '),
                source: config.dataSource.join(', '),
                pickupDate: config.pickupDates.dynamic ? config.pickupDates.dynamic.join(' - ') : config.pickupDates.fixed.join(' - '),
                status: config.isActive,
            });
        });
        return configData;
    }

    public get menuItems() {
        return [
            {
                text: 'Edit',
                action: this.handleUpdateButton,
            },
            {
                text: 'Remove',
                action: this.handleRemoveButton,
            },
        ];
    }

    async updateChangeState(configId: string, status: boolean) {
        await this.carsRateAlertService.updateRateAlertConfigurationStatus(configId, status);
        this.carsRateAlertService.resetLoadings();
    }

    async handleUpdateButton(configId: string) {
        this.$router.push({ name: 'car-settings.rate-alert', params: { id: configId } });
    }

    async handleRemoveButton(configId: string) {
        await this.carsRateAlertService.deleteRateAlertConfiguration(configId);
        this.carsRateAlertService.resetLoadings();
    }
}
